@keyframes gradient {
    0% {background: red;}
    25% {background: yellow;}
    50% {background: blue;}
    75% {background: green;}
    100% {background: red;}
}

.animated-gradient {
    animation: gradient 5s ease-in-out infinite;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.spin {
    animation: spin 2s linear infinite;
}
